import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';

function SubmitTutorForm() {
  const [ratingLG, setRatingLG] = useState(0);
  const [ratingLR, setRatingLR] = useState(0);
  const [ratingRC, setRatingRC] = useState(0);
  const [takenOfficialLSAT, setTakenOfficialLSAT] = useState(false);
  const [acceptedToLawSchool, setAcceptedToLawSchool] = useState(false);
  const [hasScholarships, setHasScholarships] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tutorName, setTutorName] = useState('');
  const [formCompleted, setFormCompleted] = useState(false);
  const [acceptedSchools, setAcceptedSchools] = useState({});
  const [scholarshipAmounts, setScholarshipAmounts] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormCompleted(true);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleSchoolCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setAcceptedSchools({ ...acceptedSchools, [id]: checked });
  };

  const handleChangeScholarshipAmount = (event, school) => {
    const { value } = event.target;
    setScholarshipAmounts({ ...scholarshipAmounts, [school]: parseInt(value, 10) });
  };

  const scholarshipOptions = () => {
    const options = [];
    for (let i = 5000; i <= 400000; i += 5000) {
      options.push(
        <option key={i} value={i}>
          {i === 400000 ? '$400,000+' : `$${i}`}
        </option>
      );
    }
    return options;
  };

  const renderScholarshipDropdowns = () => {
    const dropdowns = [];
    for (const school in acceptedSchools) {
      if (acceptedSchools[school]) {
        dropdowns.push(
          <Form.Group key={school} controlId={`${school}Scholarship`}>
            <Form.Label>{school} scholarship amount</Form.Label>
            <Form.Control
              as="select"
              custom
              onChange={(e) => handleChangeScholarshipAmount(e, school)}
            >
              {scholarshipOptions()}
            </Form.Control>
          </Form.Group>
        );
      }
    }
    return dropdowns;
  };

  return (
    <Container>
      <Row>
        <Col>
          {formCompleted ? (
            <div>
              <h2>Thank you for your feedback!</h2>
              <p>
                Your feedback is appreciated and will be reflected in {tutorName}'s
                profile.
              </p>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              {currentPage === 1 && (
                <>
                  <Form.Group controlId="tutorName">
                    <Form.Label>Who is the tutor you are submitting?</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      onChange={(e) => setTutorName(e.target.value)}
                    />
                   </Form.Group>
      <Button variant="primary" onClick={handleNextPage}>
        Next
      </Button>
    </>
  )}

  {currentPage === 2 && (
    <>
      <Form.Group controlId="ratingLG">
        <Form.Label>
          On a scale of 1 to 5 stars, how would you rate {tutorName}'s approach to Logic Games?
        </Form.Label>
        <StarRatings
          rating={ratingLG}
          starRatedColor="blue"
          changeRating={setRatingLG}
          numberOfStars={5}
          name="ratingLG"
        />
      </Form.Group>
      <Button variant="primary" onClick={handlePrevPage}>
        Back
      </Button>
      <Button variant="primary" onClick={handleNextPage}>
        Next
      </Button>
    </>
  )}

  {currentPage === 3 && (
    <>
      <Form.Group controlId="ratingLR">
        <Form.Label>
          On a scale of 1 to 5 stars, how
          would you rate {tutorName}'s approach to Logical Reasoning?
        </Form.Label>
        <StarRatings
          rating={ratingLR}
          starRatedColor="blue"
          changeRating={setRatingLR}
          numberOfStars={5}
          name="ratingLR"
        />
      </Form.Group>
      <Button variant="primary" onClick={handlePrevPage}>
        Back
      </Button>
      <Button variant="primary" onClick={handleNextPage}>
        Next
      </Button>
    </>
  )}

  {currentPage === 4 && (
    <>
      <Form.Group controlId="ratingRC">
        <Form.Label>
          On a scale of 1 to 5 stars, how would you rate {tutorName}'s approach to Reading Comprehension?
        </Form.Label>
        <StarRatings
          rating={ratingRC}
          starRatedColor="blue"
          changeRating={setRatingRC}
          numberOfStars={5}
          name="ratingRC"
        />
      </Form.Group>
      <Button variant="primary" onClick={handlePrevPage}>
        Back
      </Button>
      <Button variant="primary" onClick={handleNextPage}>
        Next
      </Button>
    </>
  )}

  {currentPage === 5 && (
    <>
      <Form.Group controlId="takenOfficialLSAT">
        <Form.Label>Have you taken an official LSAT while under this tutor?</Form.Label>
        <InputGroup>
          <Form.Check
            type="radio"
            id="takenOfficialLSATYes"
            name="takenOfficialLSAT"
            label="Yes"
            onChange={() => setTakenOfficialLSAT(true)}
          />
          <Form.Check
            type="radio"
            id="takenOfficialLSATNo"
            name="takenOfficialLSAT"
            label="No"
            onChange={() => setTakenOfficialLSAT(false)}
          />
        </InputGroup>
      </Form.Group>
      {takenOfficialLSAT ? (
        <Button variant="primary" onClick={handleNextPage}>
          Next
        </Button>
      ) : (
        <Button variant="primary" type="submit">
          Submit
        </Button>
      )}
    </>
  )}

  {currentPage === 6 && (
    <>
      <Form.Group controlId="officialScore">
        <Form.Label>What was your official score?</Form.Label>
        <Form.Control type="number" min="120" max="180" required />
      </Form.Group>
      <Button variant="primary" onClick={handleNextPage}>
        Next
      </Button>
    </>
  )}

  {currentPage === 7 && (
    <>
      <Form.Group controlId      ="acceptedToLawSchool">
        <Form.Label>Were you accepted to any law schools?</Form.Label>
        <InputGroup>
          <Form.Check
            type="radio"
            id="acceptedToLawSchoolYes"
            name="acceptedToLawSchool"
            label="Yes"
            onChange={() => setAcceptedToLawSchool(true)}
          />
          <Form.Check
            type="radio"
            id="acceptedToLawSchoolNo"
            name="acceptedToLawSchool"
            label="No"
            onChange={() => setAcceptedToLawSchool(false)}
          />
        </InputGroup>
      </Form.Group>
      {acceptedToLawSchool ? (
        <Button variant="primary" onClick={handleNextPage}>
          Next
        </Button>
      ) : (
        <Button variant="primary" type="submit">
          Submit
        </Button>
      )}
    </>
  )}

  {currentPage === 8 && (
    <>
      <h3>Accepted Schools</h3>
      <Form.Group controlId="harvard">
        <Form.Check
          type="checkbox"
          label="Harvard"
          onChange={handleSchoolCheckboxChange}
        />
      </Form.Group>
      <Form.Group controlId="yale">
        <Form.Check
          type="checkbox"
          label="Yale"
          onChange={handleSchoolCheckboxChange}
        />
      </Form.Group>
      <Form.Group controlId="stanford">
        <Form.Check
          type="checkbox"
          label="Stanford"
          onChange={handleSchoolCheckboxChange}
        />
      </Form.Group>
      <Button variant="primary" onClick={handleNextPage}>
        Next
      </Button>
    </>
  )}

  {currentPage === 9 && (
    <>
      <Form.Group controlId="hasScholarships">
        <Form.Label>Have you received any scholarships?</Form.Label>
        <InputGroup>
          <Form.Check
            type="radio"
            id="hasScholarshipsYes"
            name="hasScholarships"
            label="Yes"
            onChange={() => setHasScholarships(true)}
          />
          <Form.Check
            type="radio"
            id="hasScholarshipsNo"
            name="hasScholarships"
            label="No"
            onChange={() => setHasScholarships(false)}
          />
        </InputGroup>
      </Form.Group>
      {hasScholarships ? (
        <Button variant="primary" onClick={handleNextPage}>
          Next
        </Button>
      ) : (
        <Button variant="primary" type="submit">
          Submit
        </Button>
      )}
    </>
  )}

  {currentPage === 10 && (
    <>
      {renderScholarshipDropdowns()}
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </>
  )}
</Form>
)}

</Col>
</Row>
</Container>
  );
}
export default SubmitTutorForm;