import React, { useRef, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShieldAlt,
  faUsers,
  faHandPointer,
} from '@fortawesome/free-solid-svg-icons';

function HomePage() {
  const jumbotronBackgroundRef = useRef(null);
  const jumbotronTitleRef = useRef(null);
  const jumbotronSubtitleRef = useRef(null);
  const submitButtonRef = useRef(null);

  useEffect(() => {
    const scrollThreshold = 300;
    const maxScroll = 400;

    const handleScroll = () => {
      const scrollPosition = Math.max(window.pageYOffset - scrollThreshold, 0);
      let opacity = 1 - scrollPosition / maxScroll;

      // Ensure opacity is between 0 and 1
      opacity = Math.max(Math.min(opacity, 1), 0);

      jumbotronBackgroundRef.current.style.opacity = opacity;
      jumbotronTitleRef.current.style.opacity = opacity;
      jumbotronSubtitleRef.current.style.opacity = opacity;
      submitButtonRef.current.style.opacity = opacity;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="fade-in">
      <div className="custom-jumbotron">
        <div className="jumbotron-background" ref={jumbotronBackgroundRef}></div>
        <Container>
          <Row>
            <Col className="jumbotron-text text-center">
              <h1 
                ref={jumbotronTitleRef} 
                style={{ fontSize: 'calc(30px + 2.5vw)' }}
              >
                Welcome to TutorEval
              </h1>
              <p 
                ref={jumbotronSubtitleRef} 
                style={{ fontSize: 'calc(16px + 1vw)' }}
              >
                Find the best tutors, rate your experiences, and help others make
                informed decisions.
              </p>
              <p>
                <Button 
                  variant="primary" 
                  href="/submit" 
                  ref={submitButtonRef}
                  style={{ 
                    padding: 'calc(10px + 1vw) calc(20px + 2vw)', 
                    fontSize: 'calc(14px + 0.5vw)' 
                  }}
                >
                  Submit a Tutor
                </Button>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="features-section gradient-background">
        <Container>
          <Row>
            <Col>
              <h2 className="section-title text-center">Our Story</h2>
              <p className="section-description">
                TutorEval was founded in response to a growing need for transparency and accountability in the LSAT tutoring market. We noticed that many tutors were providing subpar services, leaving early, or even falsifying their credentials. Reviews on social media platforms would quickly get buried, making it difficult for students to make informed decisions. TutorEval is a free service dedicated to helping everyone find reliable tutors and make the most of their investment in education. We encourage you to explore reviews, ask questions in our forums, and contribute your own experiences to help future test-takers make informed decisions.
              </p>
            </Col>
          </Row>
           <Row className="mt-4">
           <Col xs={12} md={4} className="mb-3">
             <Card className="feature-card">
               <FontAwesomeIcon icon={faShieldAlt} className="feature-icon" />
               <Card.Body className="
               text-left">
               <Card.Title>Trusted Reviews</Card.Title>
               <Card.Text>
                 Read honest and unbiased reviews from students who have experienced the services of various tutors, ensuring you find the right tutor for your needs.
               </Card.Text>
             </Card.Body>
           </Card>
         </Col>
         <Col xs={12} md={4} className="mb-3">
           <Card className="feature-card">
             <FontAwesomeIcon icon={faUsers} className="feature-icon" />
             <Card.Body className="text-left">
               <Card.Title>Community Forums</Card.Title>
               <Card.Text>
                 Engage with other students in our community forums to share insights, ask questions, and discuss the best strategies for LSAT preparation.
               </Card.Text>
             </Card.Body>
           </Card>
         </Col>
         <Col xs={12} md={4} className="mb-3">
           <Card className="feature-card">
             <FontAwesomeIcon icon={faHandPointer} className="feature-icon" />
             <Card.Body className="text-left">
               <Card.Title>Easy to Use</Card.Title>
               <Card.Text>
                 Our platform is designed with user-friendliness in mind, making it simple for you to find, review, and connect with the best LSAT tutors.
               </Card.Text>
             </Card.Body>
           </Card>
         </Col>
       </Row>
     </Container>
   </div>
 </div>
);
}

export default HomePage;